/*! @name videojs-contextmenu-ui @version 5.1.0 @license Apache-2.0 */
.vjs-contextmenu-ui-menu {
  position: absolute
}

.vjs-contextmenu-ui-menu .vjs-menu-content {
  background-color: #2b333f !important;;
  background-color: rgba(43, 51, 63, .7) !important;;
  border-radius: .3em !important;
  padding: .25em !important;
  margin: 0 !important;
  list-style: none !important;
  white-space: nowrap !important;
}

.vjs-contextmenu-ui-menu .vjs-menu-item {
  border-radius: .3em !important;
  cursor: pointer !important;
  margin: 0 0 1px !important;
  padding: .5em 1em !important;
  font-size: 1em !important;
  line-height: 1.2 !important;
  text-transform: none  !important;
}

.vjs-contextmenu-ui-menu .vjs-menu-item:active, .vjs-contextmenu-ui-menu .vjs-menu-item:hover {
  background-color: rgba(0, 0, 0, .5) !important;
  text-shadow: 0 0 1em #fff !important;
}
