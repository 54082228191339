.dropzone {
  cursor: pointer;
  -webkit-border-radius: 0px ;
  -moz-border-radius: 0px ;
  border-radius: 0px ;
  &.is-dragover {
    background-color: #cfcfcf;
  }

  .dropzone-icon {
    color: #028AF4;
  }
}

.dropzone-file-area {
  border: 2px dashed #028AF4;
  background: white;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
}

.dz-hidden-input{
  left :0;
}

@include media-breakpoint-down(md) {
  .dropzone-file-area {
    width: auto;
  }
}
