@import "./form";

.video-show {
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  padding: 0 0 20px 0;

  @include media-breakpoint-down(sm) {
    padding: 20px 0 0 0;
  }

  .video {
    padding: 10px;

    .video-transcoding {
      position: relative;
      text-align: center;
      color: white;
      min-height: 300px;
    }
  }

  .cta-btn {
    min-width: 200px;
    border-radius: 5px !important;
  }

  .with-border {
    border: 1px solid rgba(230, 230, 230, .2);
    padding: 20px 10px;
    border-radius: 4px !important;
    margin-bottom: 10px;
    @include media-breakpoint-down(sm) {
      border-radius: 5px;
    }
  }

  .edit-title {
    input[type=text] {
      border-right: none;
    }

    input[type=text][readonly] {
      background-color: #eeeeee;
    }

    .form-inline {
      margin-bottom: 10px;
    }

    .form-inline, .form-group, .input-group {
      width: 100%;
    }

    .btn {
      background-color: #eeeeee;
      border: none;

      &.editing {
        color: white;
        background-color: #0c91e5;
      }
    }

    .input-group-addon {
      padding: 0;
      text-align: right;
      width: 1%;
    }

    .dropdown {
      display: inline-block;
    }
  }

  .emoji-responses {
    background-color: rgba(var(--video-color), 0.8) !important;
    font-size: 24px;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
    .emoji-item {
      padding: 10px 0;
      flex: 1;
    }
    a, a:visited, a:focus, a:active {
      text-decoration: none;
    }
  }

  .dubb-video {
    position: relative;
    .message {
      cursor:pointer;
      position: absolute;
      color: #fff;
      left: 0;
      right: 0;
      top: 10px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      h1, h4 {
        max-width: 100%;
        padding: 5px 15px;
        background-color: rgba(0, 0, 0, .5);
        line-height: 45px;
        margin: 0 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        display: table;
      }

      h4 {
        line-height: 25px;
      }
    }
  }

  .cta-group .cta-btn {
    border-radius: 5px 0 0 5px !important;
  }
  .cta-group .dropdown-toggle {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
  .cta-iframe {
    width: 100%;
    height: 600px;
    background: white;
    margin-bottom: 25px;
  }
  .product-cta {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .product-cta-btn {
      min-width: unset !important;
    }
  }
  .video-js.vjs-fluid {
    height: 100% !important;
  }
  .video-conversation-header {
    background: rgb(var(--cta-color)) !important;
  }

  .video-description {
    img {
      max-width: 100%;
    }
  }
}
