:root {
  --video-color: 0, 113, 188;
  --video-frame-color: 255, 255, 255;
  --video-header-color: 255, 255, 255;
  --cta-color: 0, 113, 188;
  --cta-text-color: 255, 255, 255;
}

.bg-frame,
.cta-iframe {
  background-color: rgba(var(--video-frame-color)) !important;
}

.page-header {
  background-color: rgba(var(--video-header-color)) !important;
}

.video-js .vjs-big-play-button:active, .video-js .vjs-big-play-button:focus, .video-js:hover .vjs-big-play-button, .video-js .vjs-big-play-button, .video-js .vjs-play-control, .vjs-play-progress.vjs-slider-bar {
  background-color: rgba(var(--video-color), 0.8) !important;
}
.video-js .vjs-volume-panel .vjs-volume-horizontal .vjs-slider .vjs-volume-level {
  color: rgba(var(--video-color), 0.8) !important;
}

.cta-btn {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: rgb(var(--cta-text-color)) !important;
  border: none !important;
  background-color: rgb(var(--cta-color)) !important;
  margin: 5px;

  &:hover {
    background-color: rgba(var(--cta-color), .9) !important;
  }
}
