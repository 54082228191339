$flexbin-row-height: 100px !default;
$flexbin-space: 5px !default;

@mixin flexbin($row-height, $space) {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  margin: - $space / 2;

  &:after {
    content: '';
    flex-grow: 999999999;
    min-width: $row-height;
    height: 0;
  }

  > * {
    position: relative;
    display: block;
    height: $row-height;
    margin: $space / 2;
    flex-grow: 1;

    &.selected > img {
      outline: solid 3px #3598dc;
    }

    > img {
      height: $row-height;
      object-fit: cover;
      max-width: 100%;
      min-width: 100%;
      vertical-align: bottom;
    }
  }

  &.flexbin-margin {
    margin: $space / 2;
  }
}

.flexbin {
  @include flexbin($flexbin-row-height, $flexbin-space);
}
