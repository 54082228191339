.multiselect__tag-icon {
  font-size: inherit;

  &:after {
    display: block;
    margin-top: -1px;
  }
}

.multiselect__placeholder {
  color: #b5b5c1;
}
