.form {
  padding-left: 10px;
  padding-right: 10px;

  &-title-color {
    color: rgb(var(--form-title-color)) !important;
  }

  &-text-color {
    color: rgb(var(--form-text-color)) !important;
  }

  &-btn {
    $color: rgb(var(--form-button-color));
    $darken-color: rgb(var(--form-button-darken-color));
    $text-color: rgb(var(--form-button-text-color));

    color: $text-color;
    background-color: $color;
    border: 1px solid $darken-color;
    padding: 10px 48px;

    &:hover {
      color: $text-color;
      background-color: $darken-color;
    }
  }
}
