// Small screen / tablet
$screen-sm-min: 768px !default;
$screen-xs-max: ($screen-sm-min - 1) !default; // 767px

.fb-btn {
  @include button-variant(#1877F2, darken(#1877F2, 5%));

  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.lk-btn {
  @include button-variant(#007bb6, darken(#007bb6, 5%));

  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.tw-btn {
  @include button-variant(#00aced, darken(#00aced, 5%));

  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.google-btn {
  @include button-variant(#4885ed, darken(#4885ed, 5%));

  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.apple-btn {
  @include button-variant(#000, #000);

  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.outlook-btn {
  @include button-variant(#0067b8, darken(#0067b8, 5%));

  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.slack-btn {
  @include button-variant(#e01563, darken(#e01563, 5%));

  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.gmail-btn {
  @include button-variant(#db3236, darken(#db3236, 5%));

  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.yb-btn {
  @include button-variant(#40A4C4, darken(#ff0000, 5%));

  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.periscope-btn {
  color: #fff;
  background-color: #40a4c4;
  border-color: #3795b3;
  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.medium-btn {
  color: #fff;
  background-color: #fff;
  border-color: #f2f2f2;
  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}

.ig-btn {
  color: #fff;
  background: radial-gradient(circle at 33% 100%, #fed373 4%, #f15245 30%, #d92e7f 62%, #9b36b7 85%, #515ecf);
  border: solid #f15245 1px;
  &:hover {
    color: #fff;
  }

  @media (max-width: $screen-xs-max) {
    font-size: 0.7em;
  }
}
