.dubb-video-list {
  display: flex;
  flex-wrap: wrap;
  .video-item {
    width: 32%;
    margin: 1%;
    @media (max-width: 768px) {
      width: 100%;
      margin: 0;
    }

    &:nth-of-type(3n) {
      margin-right: 0;
    }
    &:nth-of-type(3n+1) {
      margin-left: 0;
    }

    padding: 5px;
    position: relative;
    h5 {
      font-weight: bold;
      font-size: 13px;
    }
    span {
      color: #838383;
      font-size: 12px;
    }

    .dropdown-menu {
      margin: 0 !important;
      min-width: 100px;
      left: -100px;

      &:before, &:after {
        display: none !important;
      }

      & > li > a {
        padding: 2px 16px;
      }
    }
  }
}
