@import '~@fortawesome/fontawesome-pro/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-pro/scss/solid.scss';
@import '~@fortawesome/fontawesome-pro/scss/light.scss';
@import '~@fortawesome/fontawesome-pro/scss/brands.scss';
@import '~@fortawesome/fontawesome-pro/scss/duotone.scss';
@import "~hint.css/src/hint";
@import "~multi-brand-colors/dist/css/index.css";
@import "~toastr";
@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light.css";

// 1: Initialize
@import "init";

// 2: Bootstrap framework includes
@import "~bootstrap/scss/bootstrap";

// 3: Components
@import
    "components/utilities",
    "components/card",
    "components/wizard",
    "components/type",
    "components/breadcrumb",
    "components/nav",
    "components/alert",
    "components/buttons",
    "components/code",
    "components/dropdown",
    "components/forms",
    "components/grid",
    "components/modal",
    "components/progress",
    "components/table",
    "components/popover",
    "components/tooltip",
    "components/accordion",
    "components/checkbox",
    "components/radio",
    "components/switch",
    "components/option",
    "components/dialog",
    "components/strikethrough",
    "components/separator",
    "components/label",
    "components/loader",
    "components/symbol",
    "components/pulse",
    "components/spinner",
    "components/svg-icon",
    "components/svg",
    "components/timeline",
    "components/timeline-2",
    "components/timeline-3",
    "components/timeline-4",
    "components/timeline-5",
    "components/timeline-6",
    "components/overlay",
    "components/list",
    "components/bullet",
    "components/navi",
    "components/ribbon",
    "components/offcanvas",
    "components/toggle";

// 4: Plugins
@import
    "vendors/plugins/toastr",
    "vendors/plugins/vue-multiselect";

// 5: Layout
@import "layout/init";

@import "themes/layout/aside/dark";
@import "themes/layout/brand/light";
@import "themes/layout/header/base/light";
@import "themes/layout/header/menu/light";

// 6: slick-carousel
$slick-arrow-color: $primary;
@import "~slick-carousel/slick/slick-theme.scss";
@import "~slick-carousel/slick/slick.scss";

@import "../components/videojs-contextmenu-ui";
@import "../components/flexbin";
@import "../components/toggle-button";
@import "../components/dymanic-video-color";
@import "../components/impersonation-indicator";
@import "../components/dropzone";
@import "../components/brand-btns";
@import "../video-js.css";
@import "./keenthemes-icons/ki.css";
@import "../app/video-list";
@import "../app/video-show";
@import "custom";

[v-cloak] {
    display: none !important;
}

::placeholder {
  color: #b5b5c1;
}

.full-footer {
  width: 100%;

  ul.sub-menu {
    list-style: none;
    padding-left: 0;

    a {
      color: #BFBFBF;
    }
  }
}

.uppercase {
    text-transform: uppercase;
}

.spark-profile-photo, .spark-profile-photo-lg, .spark-profile-photo-xl, .spark-team-photo {
    border: 2px solid #d3e0e9;
    border-radius: 50%;
    height: 40px;
    padding: 2px;
    width: 40px;
}

.card-pricing {
  &-block {
    @media (min-width: map-get($grid-breakpoints, 'md')) {
      height: 250px;
    }
  }

  &-header {
    $arrow-size: 15px;
    position: relative;
    margin-bottom: $arrow-size;

    &:after {
      content: '';
      position: absolute;
      border-left: $arrow-size solid transparent;
      border-right: $arrow-size solid transparent;
      border-top: $arrow-size solid;
      height: 0;
      width: 0;
      bottom: -$arrow-size;
      left: 50%;
      margin-left: -$arrow-size / 2;
    }

    $pricing-colors: (
      "purple": $info,
      "green": $success,
      "red": $danger,
      "blue": $primary,
    );

    @each $name, $color in $pricing-colors {
      &-#{$name} {
        background-color: $color !important;

        &:after {
          border-top-color: $color !important;
        }
      }
    }
  }

  .ribbon-target {
    top: -2rem;
    .ribbon-inner {
      background-color: $danger;
    }
  }
}

.pull-right {
    float: right;
}
.aside-minimize .aside .fa-lock-alt {
    display: none;
}

.dropdown, .dropup {
  .dropdown-menu .dropdown-item {
    padding-top: 0;
    padding-bottom: 0;
    &>label {
      margin-bottom: 0;
    }
  }
}

.dropdown-toggle:empty::after {
  margin-left: 0 !important;
}

.aside-minimize {
  .aside-footer {

    &-link {
      justify-content: center;
      padding: .5rem 0 !important;
    }

    &-icon {
      margin-right: 0 !important;
    }

    &-text {
      display: none;
    }
  }
}

.aside-minimize-hover #kt_header {
  z-index: unset !important;
}

.header-hidden {
  #kt_header_mobile, #kt_header {
    display: none;
  }
  #kt_wrapper {
    padding-top: 0;
  }
}


@each $size, $length in $spacers {
  @if "#{$size}" != "0" {
    .space-x-#{$size} > * + * {
      margin-left: $length !important;
    }

    .space-y-#{$size} > * + * {
      margin-top: $length !important;
    }
  }
}
