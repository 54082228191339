.impersonation-indicator {
  position: fixed;
  bottom: 0;
  z-index: 1049;
  padding: 10px 25px;
  width: 100%;
  background: rgba(#000000, 0.9);
  color: #ffffff;
  left: 0;
  right: 0;

  .impersonation-indicator-holder {
    display: flex;
    justify-content: center;
    align-items: center;

    .impersonation-indicator-content {

      a {
        color: #fff;
        text-decoration: underline;
      }
    }

    .impersonation-indicator-action {

      .btn-times {
        font-size: 18px;
        color: #fff;
        line-height: 18px;
      }
    }
  }

  .justify-between {
    justify-content: space-between !important;
  }

  &.impersonation-indicator-top {

  }

  &.impersonation-indicator-light {
    background: #eeeeee;
    color: #333;
  }
}
