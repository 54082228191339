.inset-0 {
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
}

.toast-top-right {
  top: 75px !important;
  right: 25px;
}

.btn.btn-xs {
  padding: 0.35rem 0.5rem;
  font-size: 0.8rem;
  line-height: 1;
}

.aside-overlay {
  display: none;

  body.aside-on & {
    display: block;
  }
}

.btn.btn-outline-primary.upgrade-btn {
  color: #fff;
  background-image: linear-gradient(
      -135deg
    ,#028fff 25%,#9f55ff)
}

.timeline.timeline-2 {
  .timeline-item {
    .timeline-avatar {
      position: relative;
      z-index: 1;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      flex-shrink: 0;
      margin-right: 1rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .aside {
    .brand-toggle i {
      font-size: 2em;

      &.hide-on-minimize {
        display: inline-block;
      }
      &.show-on-minimize {
        display: none;
      }
    }

    .aside-minimize:not(.aside-minimize-hover) & {
      .header-logo {
        display: none;
      }

      .brand-toggle {
        i.hide-on-minimize {
          display: none;
        }
        i.show-on-minimize {
          display: inline-block;
        }
      }
    }
  }
}

.animation-shake {
  animation: animation-shake 2s ease infinite;
}

@keyframes animation-shake {
  0%, 100% {transform: translateX(0);}
  10%, 30%, 50%, 70%, 90% {transform: translateX(-5px);}
  20%, 40%, 60%, 80% {transform: translateX(5px);}
}


.animation-shake-y {
  animation: animation-shake-y 2s ease infinite;
}

@keyframes animation-shake-y {
  0%, 100% {transform: translateY(0);}
  10%, 30%, 50%, 70%, 90% {transform: translateY(-5px);}
  20%, 40%, 60%, 80% {transform: translateY(5px);}
}

.font-size-inherit {
  font-size: inherit !important;
}

.font-size-16px {
  font-size: 16px !important;
}

.text-inherit {
  color: inherit !important;
}

.bg-inherit {
  background-color: inherit !important;
}

.whitespace-nowrap {
  white-space: nowrap !important;
}

.multiselect__option--link {
  color: #35495e;

  &:hover {
    background: #41b883;
    outline: none;
    color: #fff;
  }
}

.rounded-left-pill {
  border-top-left-radius: $rounded-pill !important;
  border-bottom-left-radius: $rounded-pill !important;
}

.rounded-right-pill {
  border-top-right-radius: $rounded-pill !important;
  border-bottom-right-radius: $rounded-pill !important;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.pointer-events-none {
  pointer-events: none;
}

.h-fit {
  height: fit-content;
}

.w-fit {
  width: fit-content;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.object-cover {
  object-fit: cover;
}

// override mbc
.mbc-youtube {
  color: #ff0000 !important;
}

.mbc-youtube-bg {
  background-color: #ff0000 !important;
}

.mbc-x-twitter {
  color: #000 !important;
}

.mbc-x-twitter-bg {
  color: #fff !important;
}

.custom-swal2-modal--plain-cancel-button {
  .swal2-cancel {
    color: inherit;
    background-color: transparent;
  }
}
